import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { JoinUs } from '../components';
import { Slider } from '../containers';
import { medias } from '../utils/mediaQueries';
import { useStateValue } from '../state';
import checkSession from '../services/checkSession';
import { siteContent } from '../services/api';
// import moment from 'moment';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 78%;
  align-self: center;
  justify-content: center;
`;

/*
const RowContainer = styled.div`
  @media ${medias.laptopMin} {
    align-self: center;
    justify-content: space-between;
  }

  @media ${medias.laptopMax} {
    align-items: center;

    & > :not(:first-child) {
      display: none;
    }
  }
  display: flex;
  width: 90%;
`;
*/
const JoinWrapper = styled.div`
  min-height: 340px;
  @media ${medias.laptopMin} {
    margin-top: 50px;
  }
  margin-top: 40px;
`;

// const StickyBarDiv = styled.div`
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   background-color: green;
//   padding: 10px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   color: #fff;

//   p {
//     margin: 0;
//   }

//   button {
//     background-color: gray;
//     color: #fff;
//     border: none;
//     padding: 8px 16px;
//     border-radius: 4px;
//     cursor: pointer;
//     margin-right: 50px;
//   }

//   button:hover {
//     background-color: #cc7700;
//   }
// `;

// const StickyBar = ({ text, buttonText, buttonOnClick }) => {
//   return (
//     <StickyBarDiv>
//       <p>{text}</p>
//       <button onClick={buttonOnClick}>{buttonText}</button>
//     </StickyBarDiv>
//   );
// };

export const HomePage = () => {
  //const [blogPosts, setBlogPosts] = useState(null);
  // const [opened, setOpened] = useState(true);
  const [banners, setBanners] = useState([]);
  // const [bannersDataInicio, setBannersDataInicio] = useState(null);
  // const [bannersDataTermino, setBannersDataTermino] = useState(null);
  const [
    {
      segments: { segments },
      session: { userId },
    },
    dispatch,
  ] = useStateValue();

  useFetch({
    url: urls.siteContent.segments,
    method: method.GET,
    condition: () => !segments,
    callback: data =>
      dispatch({
        type: 'setSegments',
        newSegments: data,
      }),
  });

  useEffect(() => {
    if (!userId) {
      checkSession(dispatch, false);
    }
  }, [dispatch, userId]);

  /*
  useEffect(() => {
    fetchBlogPosts();
  }, [fetchBlogPosts]);
*/
  useEffect(() => {
    // const banner = sessionStorage.getItem('banner');
    // if (banner) {
    //   setOpened(false);
    // }

    const fetchBanners = async () => {
      const response = await siteContent.banners();
      if (response.ok && response.data) {
        setBanners(response.data);
        if (response.data.length) {
          // setBannersDataInicio(response.data[0].dataInicio);
          // setBannersDataTermino(response.data[0].dataTermino);
        }
      }
    };
    fetchBanners();
  }, []);
  /*
  const fetchBlogPosts = useCallback(async () => {
    await fetch(
      'https://blog.vestidasdebranco.com.br/wp-json/wp/v2/posts?tags=6'
    ).then(response => response.json().then(data => handleBlogPostsData(data)));
  }, []);

  const handleBlogPostsData = data => {
    const mappedData = data.map(post => ({
      id: post.id,
      title: post.title.rendered,
      description: post.excerpt.rendered,
      link: post.link,
      imageCode: post.featured_media,
    }));

    setBlogPosts(mappedData);
  };
*/
  // const onClose = () => {
  //   setOpened(false);
  //   sessionStorage.setItem('banner', true);
  // };

  // const showBanner = () => {
  //   const todayDate = moment().format('YYYY-MM-DD');
  //   return todayDate >= bannersDataInicio && todayDate <= bannersDataTermino;
  // };

  return (
    <>
      {/* <StickyBar
        text="PRÓXIMA RODADA DE NEGÓCIOS : IT'S TIME - 09/06/24 - TREVILLE"
        buttonText="Inscreva-se"
        buttonOnClick={() =>
          (window.location.href =
            'http://bit.ly/inscricaorodadassiteredecelebrar')
        }
      /> */}
      <Wrapper>
        {/* {bannersDataInicio && bannersDataTermino && showBanner() && (
        <BannerModal closeModal={onClose} opened={opened} banners={banners} />
      )} */}
        <Slider dynamicBanners={banners} />
        <StyledContainer>
          <JoinWrapper>
            <JoinUs />
          </JoinWrapper>
          {/*
        <RowContainer>
          {blogPosts &&
            blogPosts.map(item => <BlogPost key={item.id} data={item} />)}
        </RowContainer>
          */}
        </StyledContainer>
      </Wrapper>
    </>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
